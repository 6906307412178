@font-face {
	font-family: 'Segoe UI';
	src: url('./fonts/normal/segoeui.eot');
	src: local("Segoe UI"),
		 local("Segoe"),
		 local("Segoe WP"),
		 url('./fonts/normal/segoeui.eot?#iefix') format('embedded-opentype'),
		 url('./fonts/normal/segoeui.woff') format('woff'),
		 url('./fonts/normal/segoeui.svg#SegoeUI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI Semibold';
	src: url('./fonts/semibold/seguisb.eot');
	src: local("Segoe Semibold"),
		 local("Segoe WP Semibold"),
		 url('./fonts/semibold/seguisb.eot?#iefix') format('embedded-opentype'),
		 url('./fonts/semibold/seguisb.woff') format('woff'),
		 url('./fonts/semibold/seguisb.svg#SegoeUISemibold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI Bold';
	src: url('./fonts/bold/segoeuib.eot');
	src: local("Segoe Bold"),
		 local("Segoe WP Bold"),
		 url('./fonts/bold/segoeuib.eot?#iefix') format('embedded-opentype'),
		 url('./fonts/bold/segoeuib.woff') format('woff'),
		 url('./fonts/bold/segoeuib.svg#SegoeUI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI Light';
	src: url('./fonts/light/segoeuil.eot');
	src: local("Segoe UI Light"),
		 local("Segoe WP Light"),
		 url('./fonts/light/segoeuil.eot?#iefix') format('embedded-opentype'),
		 url('./fonts/light/segoeuil.woff') format('woff'),
		 url('./fonts/light/segoeuil.svg#SegoeUI') format('svg');
	font-weight: normal;
	font-style: normal;
}
